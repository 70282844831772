import React from 'react'
import styles from './Help.module.scss'
import Button from '../common/Button'
import {
  AcademicCapIcon,
  BookOpenIcon,
  MapIcon,
  PaperAirplaneIcon
} from '@heroicons/react/24/outline'
import GridItem from './GridItem'
import Categories from './Categories'
import { Link } from 'gatsby'

import loadable from '@loadable/component'
import _ from 'lodash'
import { ArrowRightIcon } from '@atlas-co/icons'

const AnimationComponent = loadable(() =>
  import('../animations/AnimationComponent.js')
)

const Help = ({ docs, guides }) => {
  return (
    <>
      <div className={styles.section}>
        <div className={styles.content}>
          <div>
            <div className={styles.headerContent}>
              <div className={styles.heading}>
                <h1>Help & Documentation</h1>
                <p>Learn how to make the most out of Atlas</p>
              </div>
              <div className={styles.buttonGrid}>
                <div className={`${styles.buttonCard}`}>
                  <div className={styles.cardIcon}>
                    <BookOpenIcon />
                  </div>
                  <div className={styles.buttonContent}>
                    <h4 className={styles.text}>Documentation</h4>
                    <p className={styles.subText}>
                      Start with the basics, or explore advanced features to
                      become an Atlas expert
                    </p>
                  </div>
                  <Button
                    variant="minimal"
                    href="/help/docs/"
                    rightIcon={
                      <ArrowRightIcon width={20} color="var(--primary)" />
                    }
                  >
                    Learn the basics
                  </Button>
                </div>
                <div className={`${styles.buttonCard}`}>
                  <div className={styles.cardIcon}>
                    <AcademicCapIcon />
                  </div>
                  <div className={styles.buttonContent}>
                    <h4 className={styles.text}>Guides</h4>
                    <p className={styles.subText}>
                      Discover use case-specific guides to solve real situations
                      and get the most out of Atlas
                    </p>
                  </div>
                  <Button
                    variant="minimal"
                    href="/help/guides/"
                    rightIcon={
                      <ArrowRightIcon width={20} color="var(--primary)" />
                    }
                  >
                    Start making maps
                  </Button>
                </div>
                <div className={`${styles.buttonCard}`}>
                  <div className={styles.cardIcon}>
                    <MapIcon />
                  </div>
                  <div className={styles.buttonContent}>
                    <h4 className={styles.text}>Community Maps</h4>
                    <p className={styles.subText}>
                      Explore best-in-class maps made by skilled creators on
                      Atlas
                    </p>
                    <Button
                      variant="minimal"
                      href="/community/maps/"
                      rightIcon={
                        <ArrowRightIcon width={20} color="var(--primary)" />
                      }
                    >
                      Discover maps
                    </Button>{' '}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.animationContainer}>
              <AnimationComponent selector="roads" />
            </div>
            <div className={styles.cardsSection}>
              <div className={styles.cardsSectionHeader}>
                <h3>Documentation</h3>
                <div className={styles.seeAllContainer}>
                  <Button
                    variant="minimal"
                    href="/help/docs/"
                    rightIcon={
                      <ArrowRightIcon width={20} color="var(--primary)" />
                    }
                  >
                    See all docs
                  </Button>
                </div>
              </div>

              <div className={styles.list}>
                {docs.map(doc => (
                  <Link to={`/docs/${_.kebabCase(doc.title)}/`}>
                    <div className={styles.position}>
                      <div className={styles.positionDetails}>
                        <h4>
                          {doc.title}
                          <ArrowRightIcon />{' '}
                        </h4>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              <div className={styles.cardsSectionHeader}>
                <h3>Guides</h3>
                <div className={styles.seeAllContainer}>
                  <Button
                    variant="minimal"
                    href="/help/guides/"
                    rightIcon={
                      <ArrowRightIcon width={20} color="var(--primary)" />
                    }
                  >
                    See all docs
                  </Button>
                </div>
              </div>

              <div className={styles.list}>
                {guides.map(doc => (
                  <Link to={`/guides/${_.kebabCase(doc.title)}/`}>
                    <div className={styles.position}>
                      <div className={styles.positionDetails}>
                        <h4>
                          {doc.title}
                          <ArrowRightIcon />{' '}
                        </h4>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Help
