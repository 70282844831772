import React, { useState } from 'react'
import Layout from '../components/common/Layout'
import Fade from 'react-reveal/Fade'
import Help from '../components/help/Help'

const HelpPage = ({ data }) => {
  const guides = data.guides.nodes
    .filter(node => node.frontmatter.template === 'SingleArticle')
    .flatMap(node => node.frontmatter)
    .slice(0, 6)

  const docs = data.docs.nodes
    .filter(node => node.frontmatter.template === 'SingleArticle')
    .flatMap(node => node.frontmatter)
    .slice(0, 6)

  return (
    <Layout>
      <main>
        <Help guides={guides} docs={docs} />
      </main>
    </Layout>
  )
}

export default HelpPage

export const pageQuery = graphql`
  query {
    docs: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/docs/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage
          featured
          template
          date(formatString: "MMMM D, YYYY")
        }
        fields {
          slug
        }
      }
    }
    guides: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/guides/" } }
    ) {
      nodes {
        id
        frontmatter {
          title
          featuredImage
          featured
          template
          date(formatString: "MMMM D, YYYY")
        }
        fields {
          slug
        }
      }
    }
  }
`
